import React  from "react";
import axios from "axios";
import styles from "../styles/css/content/homecontent.module.css";
import feapiurl from "../feapiurl";
import MasterDivs from "../content/MasterDivs";
import ImageSlider from "../content-parts/ImageSlider";
import ImageGallery from "../content-parts/ImageGallery";
import NewsSlide from "../content-parts/NewsSlide";
import imageurl from "../imageurl";
function LoadImageTeaserContent({ pageid,marginBottom, className, mediaType  }) {

    const [loadedImages, setLoadedImages] = React.useState([]);

    const fetchurl =feapiurl+ "getteaserimage.php";
   React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, {pageid});
                const result = response.data;
console.log(response.data);
                setLoadedImages(result);


            }

             catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, [pageid]);

    return (
        <MasterDivs hasFlexRow={false} marginBottom="0px">

<section className={styles.teaserimage}>
                {loadedImages && Array.isArray(loadedImages) && loadedImages.length > 0 ? (
                    loadedImages.map((image, index) => (
                        <img key={image.paimg_name} src={imageurl+"/"+image.paimg_name} alt={image.paimg_name}/>
                    ))
                ) : (
                    <p>No images found</p>
                )}



</section>
        </MasterDivs>
                )


}
export default LoadImageTeaserContent;